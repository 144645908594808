import { Injectable } from '@angular/core';
import { BonusRate, DOCUMENT_TYPE, Dictionary, InterestPeriod, Investment, InvestmentProduct, InvestmentTerm, Rate, RateType, TransferFundsInput } from '@earnr/earnr-shared/dist/models';
import { Observable, forkJoin } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { subscriptionAction } from '../../model/subscriptions';
import { ApiService } from '../api/api.service';
import { AppStateService } from '../app-state/app-state.service';

interface GetInvestmentRangeInput {
  product: InvestmentProduct
  term: InvestmentTerm
  currency: string
  legalAccountId: string
}

@Injectable({
  providedIn: 'root'
})
export class InvestmentService {

  constructor(
    private apiService: ApiService,
    private appState: AppStateService,
  ) {

    /**
     * Start Subscription for investments
     */
    const statement = `subscription onInvestmentUpdate {
      onInvestmentUpdate {
        action
        data
        __canRead
      }
    }`;

    this.appState.graphqlSubscriptions = [this.apiService
      .subscription<{ data: Investment, action: subscriptionAction, owner: string }>(statement, 'onInvestmentUpdate')
      .subscribe(payload => {
        const { data, action } = payload
        console.info(`[Subscription ${action} Investment]`, data);

        const records = this.appState.investments;
        if (!Array.isArray(records[data.legalAccountId])) {
          return;
        }

        const legalAccountAvailability = this.appState.liquidity[data.legalAccountId];

        if (action === 'DELETE') {
          records[data.legalAccountId] = records[data.legalAccountId].filter(inv => inv.id !== data.id);
        } else {
          // Assuming we use optimistic UI principle when adding a record, we are
          // just overwriting it for update or insert
          records[data.legalAccountId] = records[data.legalAccountId].map(inv => {
            if (inv.id === data.id) {
              inv = { ...inv, ...data };
            }

            if (inv.liquidity && legalAccountAvailability) {
              const currentIndex = legalAccountAvailability.liquidity.findIndex(item => item.investmentId === inv.id)
              if (currentIndex === -1) {
                legalAccountAvailability.liquidity.push(inv.liquidity)
              }
              else {
                legalAccountAvailability.liquidity[currentIndex] = inv.liquidity
              }
            }

            return inv;
          })
        }
        this.appState.investments = records;
        this.appState.liquidity[data.legalAccountId] = legalAccountAvailability
        this.appState.liquidity = this.appState.liquidity

      })];
  }

  /**
   *
   * @returns
   */
  refreshInvestments() {
    const investmentCollection$ = {} as Dictionary<Observable<Investment[]>>;
    this.appState.legalAccounts.forEach(legalAccount => {
      const investments$ = this.getInvestments(legalAccount.investmentIds);
      investmentCollection$[legalAccount.id] = investments$;
    });
    return forkJoin(investmentCollection$)
      .pipe(
        tap(val => {
          console.log('REFRESHING INVESTMENTS', val)
          this.appState.investments = val;
        }),
        map(val => true)
      );

  }

  getInvestmentConfirmation(id: string, type: DOCUMENT_TYPE, transactionId?: string) {
    const statement = `
      query getPdf($input: PdfInput!) {
        getPdf(input: $input)
      }
    `;
    return this.apiService.graphql<string>(statement, {
      input: {
        id,
        reportType: type,
        transactionId: type === DOCUMENT_TYPE.WITHDRAWAL_TRANSACTION_CONFIRMATION ? transactionId : undefined
      }
    }, 'getPdf');
  }

  getStatements(legalAccountId: string, type: DOCUMENT_TYPE, id?: string) {
    const statement = `
      query getPdf($input: PdfInput!) {
        getPdf(input: $input)
      }
    `;
    return this.apiService.graphql<string>(statement, {
      input: {
        id,
        legalAccountId,
        reportType: type
      }
    }, 'getPdf');
  }

  getBonusRates(codes: string[]) {
    const statement = `
      query getBonusRates($codes: [String]!) {
        getBonusRates(codes: $codes) {
          expiry
          rate
        }
      }
    `;
    return this.apiService.graphql<BonusRate[]>(statement, {
      codes,
    }, 'getBonusRates');
  }



  getProductRates(codes: string[]) {
    const statement = `
      query getRates($input: GetRatesInput!) {
        getRates(input: $input) {
          monthRate
          quarterRate
          maturityRate
          version
          code
        }
      }
    `;
    return this.apiService.graphql<Rate[]>(statement, {
      input: {
        codes,
        rateType: RateType.BASE_RATE
      }
    }, 'getRates');
  }

  getTierRates(code: string) {
    const statement = `
      query getTierRates($code: String!) {
        getTierRates(code: $code) {
          code
          monthRate
          quarterRate
          maturityRate
          tier
      }
    }
    `;

    return this.apiService.graphql<Rate[]>(statement, {
      code
    }, 'getTierRates');
  }

  getWithdrawalRange(investmentId: string) {
    const statement = `
      query getWithdrawalRange($investmentId: ID!) {
        getWithdrawalRange(investmentId: $investmentId) {
          min
          max
        }
      }
    `;
    return this.apiService.graphql<GetWithdrawalRangeResponse>(statement, {
      investmentId
    }, 'getWithdrawalRange');
  }

  getMaxInvestmentAmount(legalAccountId: string) {
    const statement = `
      query getMaxInvestmentAmount($legalAccountId: ID!) {
        getMaxInvestmentAmount(legalAccountId: $legalAccountId) {
          MONTHS_3
        }
      }
    `;
    return this.apiService.graphql<GetMaxInvestmentAmountResponse>(statement, {
      legalAccountId
    }, 'getMaxInvestmentAmount');
  }

  getInvestmentRange(input: GetInvestmentRangeInput) {

    const statement = `
      query getInvestmentRange($input: GetInvestmentRangeInput) {
        getInvestmentRange(input: $input) {
          min
          max
        }
      }
    `;

    return this.apiService.graphql<GetWithdrawalRangeResponse>(statement, {
      input
    }, 'getInvestmentRange');

  }

  getInvestments(investmentIds: string[]): Observable<Investment[]> {
    const statement = `
      query getInvestments($investmentIds: [ID]!) {
        getInvestments(investmentIds: $investmentIds) {
          id
          productName
          currentBalance
          awaitingFunds
          legalAccountId
          term
          status
          bonusCode
          createdThroughTransfer
          interestPeriod
          accruedInterest
          interestWitheld
          bonusCodeItem {
            bonusRateCode
            bonusRate
            expiry
          }
          productCode
          interestPayment
          investmentCurrency
          externalId
          applicationAmount
          interestSummary {
            financialYearInterest {
              financialYear
              interest
              witholdingTax
            }
            yearToDateInterest
            yearToDateInterestWitheld
            lifetimeInterest
          }
          currentInvestmentDetail {
            investmentAmount
            startDate
            maturityDate
            noticeDate
            baseRate
            bonusRate
          }
          investmentDetails {
            investmentAmount
            startDate
            maturityDate
            noticeDate
            baseRate
            bonusRate
          }
          transactions {
            ... on InvestmentWithdrawalTransaction {
              id
              type
              amount
              withdrawalDate
              dateCreated
              transferToInvestmentId
              transferToTransactionId
              description
              internalWithdrawal
            }
            ... on InvestmentStartTransaction {
              type
              amount
              description
              startDate
              dateCreated
            }

            ... on InvestmentInterestPaymentTransaction {
              type
              startPeriod
              endPeriod
              paymentType
              interestPayment
              interestPaymentDate
              description
              interest
              {
                days
                rate
              }
              dateCreated
            }

            ... on InvestmentFundsReceivedTransaction {
              id
              type
              amount
              fundsReceivedDate
              description
              dateCreated
              transferFromInvestmentId
              transferFromTransactionId
            }

            ... on InvestmentInterestReinvestmentTransaction {
              type
              amount
              description
              startPeriod
              endPeriod
              paymentType
              reinvestmentDate
              dateCreated
            }

            ... on InvestmentMonthlyInterestTransaction {
              type
              accruedInterest
              period
            }

            ... on InvestmentMaturityInterestTransaction {
              type
              accruedInterest
              startDate
              endDate
            }

            ... on InvestmentAdjustmentTransaction {
              type
              amount
              adjustmentDate
              dateCreated
            }
          }
        }
      }
    `;
    return this.apiService.graphql<Investment[]>(statement, {
      investmentIds
    }, 'getInvestments');
  }

  updateInvestment(data: UpdateInvestmentInput) {

    const input: Dictionary<any> = {
      ...data,
    };

    const statement = `mutation updateInvestment($input: UpdateInvestmentInput) {
      updateInvestment(input: $input)
    }`

    return this.apiService.graphql<boolean>(statement, { input }, 'updateInvestment')

  }

  cancelInvestment(id: string) {

    const statement = `mutation cancelInvestment($id: ID!) {
      cancelInvestment(id: $id) {
        status
        message
      }
    }`;

    return this.apiService.graphql<CancelInvestmentResponse>(statement, {
      id
    }, 'cancelInvestment');

  }

  cancelTransfer(id: string) {
    const statement = `mutation cancelTransfer($id: ID!) {
      cancelTransfer(id: $id) {
        status
        message
      }
    }`;

    return this.apiService.graphql<CancelTransferResponse>(statement, {
      id
    }, 'cancelTransfer');
  }

  addInvestment(data: AddInvestmentInput) {
    console.log('data', data)
    const input: Dictionary<any> = {
      ...data,
    };
    const statement = `mutation addInvestment($input: AddInvestmentInput) {
      addInvestment(input: $input) {
        status
        investmentId
        investment {
          id
          legalAccountId
          productName
          currentBalance
          term
          awaitingFunds
          status
          bonusCode
          bonusCodeItem {
            bonusRateCode
            bonusRate
            expiry
          }
          productCode
          externalId
          applicationAmount
          investmentDetails {
            investmentAmount
            startDate
            maturityDate
            noticeDate
            baseRate
            bonusRate
          }
        }
      }
    }`;
    return this.apiService
      .graphql<AddInvestmentResponse>(statement, { input }, 'addInvestment')
      .pipe(
        tap(res => {
          // apply optimistic UI pattern to make sure investment record is in appState
          res.investment.interestSummary = {
            financialYearInterest: [],
            lifetimeInterest: 0,
            yearToDateInterest: 0,
            yearToDateInterestWitheld: 0
          };
          res.investment.transactions = [];
          this.appState.addInvestment(res.investment);
        })
      )
  }

  addWithdrawal(data: AddWithdrawalInput) {
    const input: Dictionary<any> = {
      ...data,
    };
    const statement = `mutation addWithdrawal($input: AddWithdrawalInput) {
      addWithdrawal(input: $input) {
        status
        investmentId
        withdrawalDate
      }
    }`;
    return this.apiService.graphql<AddWithdrawalResponse>(statement, { input }, 'addWithdrawal');
  }

  transferFunds(data: Omit<TransferFundsInput, 'updatedBy' | 'platform'>) {
    const input: Dictionary<any> = {
      ...data,
    };
    const statement = `mutation transferFunds($input: TransferFundsInput!) {
      transferFunds(input: $input) {
        status
        heading
        message
      }
    }`;
    return this.apiService.graphql<TransferFundsResponse>(statement, { input }, 'transferFunds');
  }

  createTransferApprovals(data: Omit<TransferFundsInput, 'updatedBy' | 'platform'>) {
    const input: Dictionary<any> = {
      ...data,
    };
    const statement = `mutation createTransferApprovals($input: TransferFundsInput!) {
      createTransferApprovals(input: $input) {
        status
        heading
        message
        messageTwo
      }
    }`;
    return this.apiService.graphql<CreateTransferApprovalsOutput>(statement, { input }, 'createTransferApprovals');
  }

  isApprovalRequiredForTransfer(data: Omit<TransferFundsInput, 'updatedBy' | 'platform'>) {
    const input: Dictionary<any> = {
      ...data,
    };

    const statement = `query isApprovalRequiredForTransfer($input: TransferFundsInput) {
      isApprovalRequiredForTransfer(input: $input) {
        status
        message
        required
      }
    }`;

    return this.apiService.graphql<IsApprovalRequiredForTransferOutput>(statement, { input }, 'isApprovalRequiredForTransfer');
  }

  deleteWithdrawal(data: DeleteWithdrawalInput) {
    const input: Dictionary<any> = {
      ...data
    };
    const statement = `mutation deleteWithdrawal($input: DeleteWithdrawalInput) {
      deleteWithdrawal(input: $input)
    }`;
    return this.apiService.graphql<boolean>(statement, { input }, 'deleteWithdrawal');
  }
}

interface DeleteWithdrawalInput {
  investmentId: string;
  transactionId: string;
}

interface AddWithdrawalInput {
  investmentId: string;
  withdrawalAmount: number;
  withdrawalDate?: string;
  source: string;
}

interface AddInvestmentInput {
  investmentAmount: number;
  productCode: string;
  legalAccountId: string;
  interestReinvested: boolean;
  interestPeriod: InterestPeriod;
}

interface UpdateInvestmentInput {
  id: string
  interestReInvested: boolean
}

export enum CreateStatus {
  ADD = 'ADD',
  CREATED = 'CREATED',
  DELETE = ' DELETE',
  CONFIRMED = 'CONFIRMED',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
};


export interface AddInvestmentResponse {
  status: CreateStatus;
  investmentId?: string;
  investment?: Investment;
}
export interface AddWithdrawalResponse {
  status: CreateStatus;
  investmentId?: string;
  withdrawalDate?: string;
}
export interface TransferFundsResponse {
  status: CreateStatus;
  heading: string;
  message: string;
}
export interface CreateTransferApprovalsOutput {
  status: CreateStatus;
  heading?: string;
  message?: string;
  messageTwo?: string;
}
export interface IsApprovalRequiredForTransferOutput {
  status: CreateStatus;
  message?: string
  required?: boolean
}
export interface GetWithdrawalRangeResponse {
  min: number;
  max: number;
}
export interface GetMaxInvestmentAmountResponse {
  MONTHS_3?: number;
  MONTHS_6?: number;
  MONTHS_12?: number;
  MONTHS_18?: number;
}
export interface CancelInvestmentResponse {
  status: string
  message?: string
}
export interface CancelTransferResponse {
  status: string
  message?: string
}